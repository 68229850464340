"use client";

import { cn } from "@/lib/utils";
import { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { Dialog, DialogContent } from "./ui/dialog";

const LoadingModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
      }}
    >
      <DialogContent
        className={cn(
          "p-4 h-40 w-80 overflow-y-scroll scrollbars-hidden outline-none"
        )}
      >
        <h1 className="text-lg font-medium">Loading...</h1>
        <ImSpinner2 className="animate-spin h-6 w-6" />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
