"use client";

import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { SearchIcon } from "lucide-react";
import dynamic from "next/dynamic";
import { useState } from "react";

const SearchBarPlaceholder = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div className="relative w-full" onClick={onClick}>
      <SearchIcon className="absolute left-2 md:left-4 top-1/2 -translate-y-1/2 w-[18px] h-[18px] md:h-6 md:w-6 z-10" />
      <Input
        type="search"
        placeholder="Search by City/Property/Area"
        className={cn(
          "px-8 md:px-12 py-3 md:py-[26px] border border-gray-100 rounded-2xl drop-shadow-md focus-visible:outline-none focus-visible:ring-offset-0 focus-visible:border-gray-400 focus-visible:outline-offset-0 focus-visible:ring-0 text-sm md:text-base appearance-none [&::-webkit-search-cancel-button]:hidden",
          className
        )}
        value=""
        onChange={() => {}}
      />
    </div>
  );
};

export default function Search({ className }: { className?: string }) {
  const [searchClicked, setSearchClicked] = useState<boolean>(false);

  const SearchBar = dynamic(() => import("./search-bar"), {
    ssr: false,
    loading: () => <SearchBarPlaceholder className={className} />,
  });

  return (
    <>
      {searchClicked ? (
        <SearchBar className={className} />
      ) : (
        <SearchBarPlaceholder
          onClick={() => setSearchClicked(true)}
          className={className}
        />
      )}
    </>
  );
}
